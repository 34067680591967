import React, {useEffect, useState} from "react";
import md from "../utils/mobile_detection";
import {Link, TFunction} from "gatsby-plugin-react-i18next";

interface NavItemData {
    url: string,
    label: string, // translation key
}

const NavItems: NavItemData[] = [
    {url: '/', label: 'translation:nav.aboutUs'},
    {url: '/airline', label: 'translation:nav.airlineRoutes'},
    {url: '/services', label: 'translation:nav.productServices'},
    // {url: '/payment', label: 'translation:nav.payment'},
    // {url: '/contact', label: 'translation:nav.contact'},
];

interface TranslationProps {
    t: TFunction,
    i18n: any,
}

export function NavigationMenu(props: TranslationProps) {
    const [isMenuActive, setIsMenuActive] = useState(false);

    function cleanUp() {
        if (isMenuActive && !md.isWidthMobile()) {
            setIsMenuActive(false)
        }
    }

    useEffect(() => {
        md.addFunction(cleanUp);

        return () => {
            md.removeFunction(cleanUp);
        }
    }, []);

    function handleMenuClick(evt: React.MouseEvent<HTMLElement>) {
        if (md.isWidthMobile()) {
            let target = evt.target as HTMLElement;
            if (target.tagName !== 'A' && target.tagName !== 'LI' && target.tagName !== 'BUTTON') {
                setIsMenuActive(!isMenuActive);
            }
        }
    }

    function handleMenuCloseClick() {
        setIsMenuActive(false);
    }

    return (
        <nav onClick={handleMenuClick} className={isMenuActive ? 'active' : ''}>
            <div>
                <div>
                    <button className={'button close'} onClick={handleMenuCloseClick}/>
                    <ul>{NavItems.map((ni, idx) => <NavigationMenuItem key={idx} translation={props} data={ni}
                                                                       handleMenuCloseClick={handleMenuCloseClick}/>)}</ul>
                </div>
            </div>
        </nav>
    )
}

interface NavigationMenuItemProps {
    translation: TranslationProps,
    data: NavItemData,
    handleMenuCloseClick: React.MouseEventHandler<HTMLButtonElement>,
}

function NavigationMenuItem({translation, data, handleMenuCloseClick}: NavigationMenuItemProps) {
    let i18n = translation.i18n;
    let pathname = '';
    if (typeof window !== `undefined`) {
        pathname = i18n.originalPath === '/' ? i18n.originalPath : i18n.originalPath.endsWith('/') ? i18n.originalPath.slice(0, -1) : i18n.originalPath;
    }

    let label = translation.t(data.label);
    return (
        <li>{
            pathname === data.url ?
                <button type={'button'} className={'current'} onClick={handleMenuCloseClick}>{label}</button> :
                <Link to={data.url}
                      className={pathname === data.url ? 'current' : ''}>{label}</Link>
        }
        </li>
    )
}
