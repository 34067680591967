let _isWidthMobile: boolean = true;
let fna: { (data: boolean): void; } [] = [];

if (typeof window !== `undefined`) {
    window.addEventListener('resize', () => {
        if (_isWidthMobile !== getMobileWebState()) {
            _isWidthMobile = !_isWidthMobile;
            for (let i = 0; i < fna.length; i++) {
                fna[i](isWidthMobile());
            }
        }
    });
    _isWidthMobile = getMobileWebState();
}

function getMobileWebState(): boolean {
    // get mobile/desktop state
    return window.matchMedia('(max-width: 481px)').matches;
}

function isWidthMobile(): boolean {
    return _isWidthMobile
}

function _addFunction(f: (data: boolean) => void) {
    if (fna.indexOf(f) === -1) {
        fna.push(f);
    }
}

function _removeFunction(f: (data: boolean) => void) {
    let idx = fna.indexOf(f);
    if (idx > -1) fna.splice(idx, 1);
}

export default {
    isWidthMobile: isWidthMobile,
    addFunction: _addFunction,
    removeFunction: _removeFunction,
}