// import images
import favicon from "../images/favicon.ico";

// import js
import React, {SetStateAction, useContext, useEffect, useState} from "react";
import md from "../utils/mobile_detection";
import {Helmet, Link, TFunction} from "gatsby-plugin-react-i18next";
import {NavigationMenu} from "./navigation_menu";
import ModalBox, {BaseModalBoxContent, ModalBoxProps} from "./modal_box";

// languages
interface LanguageData {
    label: string,
    mbLabel: string, // modal box use a longer label according to design
    value: string,
}

const languages: LanguageData[] = [{
    label: 'EN',
    mbLabel: 'English',
    value: 'en',
}, {
    label: '繁',
    mbLabel: '繁體',
    value: 'zh-Hant',
}, {
    label: '简',
    mbLabel: '简体',
    value: 'zh-Hans',
}];

// context
interface BaseContextInterface {
    modalBox: ModalBoxProps | null,
    setModalBox: React.Dispatch<SetStateAction<ModalBoxProps | null>>,
}

export const BaseContext = React.createContext<BaseContextInterface>({} as BaseContextInterface);

// base
interface BaseProps {
    title: string,
    pageDescription?: string,
    children: React.ReactNode,

    // translation
    t: TFunction,
    i18n: any,
}

// this is a shared component for pages
// Ref: https://www.gatsbyjs.org/docs/layout-components/#how-to-prevent-layout-components-from-unmounting
export default function Base({title, pageDescription, t, i18n, children}: BaseProps) {
    const [modalBox, setModalBox] = useState<ModalBoxProps | null>(null);

    return (
        <div className={'wrapper'}>
            <BaseContext.Provider value={{
                modalBox: modalBox,
                setModalBox: setModalBox,
            }}>
                <Helmet>
                    <link rel="icon" href={favicon}/>
                    <title>Angie Express | {title}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                    {pageDescription && <meta name="description" content={pageDescription}/>}
                </Helmet>
                <Header t={t} i18n={i18n}/>
                {children}
                <Footer t={t}/>
                {modalBox && <ModalBox {...modalBox}>{modalBox.children}</ModalBox>}
            </BaseContext.Provider>
        </div>
    )
};


interface HeaderProps {
    t: TFunction,
    i18n: any,
}

function Header(props: HeaderProps) {
    return (
        <header>
            <Link to="/" className="header-logo"/>
            <NavigationMenu {...props}/>
            <LanguageSelection {...props}/>
        </header>
    )
}

function LanguageSelection(props: HeaderProps) {
    const baseContext = useContext(BaseContext);
    const [mbOpened, setMbOpened] = useState(false);

    // close language modal box
    useEffect(() => {
        md.addFunction(cleanUp);

        return () => {
            md.removeFunction(cleanUp);
        }
    });

    function cleanUp() {
        if (!md.isWidthMobile() && mbOpened) {
            baseContext.setModalBox(null);
        }
    }

    function handleLangChange(lang: string) {
        props.i18n.changeLanguage(lang);
    }

    function handleLangMobileClick() {
        if (!md.isWidthMobile()) return;
        // open modal box
        let mbTitle = props.t('translation:language');
        let mb: ModalBoxProps = {
            label: mbTitle,
            listOfOptions: true,
            children: (
                <BaseModalBoxContent header={mbTitle} headerClassName={"primary"}>
                    <div className={"modal-box-content"}>
                        <ul>{languages.map((lang, idx) => makeLangLi(lang, idx, true))}</ul>
                    </div>
                </BaseModalBoxContent>
            )
        };
        baseContext.setModalBox(mb);
        setMbOpened(true);
    }

    function makeLangLi(lang: LanguageData, idx: number, isMb: boolean) {
        if (!lang) return;
        return <li key={idx} className={lang.value === props.i18n.language ? 'selected' : ''}
                   onClick={handleLangChange.bind(null, lang.value)}><span>{isMb ? lang.mbLabel : lang.label}</span>
        </li>
    }

    function getSelectedLanguage() {
        for (let i = 0; i < languages.length; i++) {
            if (languages[i].value === props.i18n.language) return languages[i].label;
        }
    }

    return (
        <aside id={"language-div"} onClick={handleLangMobileClick}>
            <ul id="language">
                <li className={"selected"}><span>{getSelectedLanguage()}</span></li>
                {languages.map((lang, idx) => {
                    if (lang.value !== props.i18n.language) return makeLangLi(lang, idx, false)
                })}</ul>
        </aside>
    )
}

interface FooterProps {
    t: TFunction,
}

function Footer({t}: FooterProps) {
    let year = new Date().getFullYear();
    return (
        <footer>
            <div>
                <div>
                    <Link to="/" className="footer-logo"/>
                    <h2>{t('translation:footer.name')}</h2>
                </div>
                <div className={"footer-addresses"}>
                    <div>
                        <div>{t('translation:footer.hkOffice.name')}
                            <p>{t('translation:footer.address')}: {t('translation:footer.hkOffice.address')}</p>
                            <p>{t('translation:footer.email')}: info@agel.com.hk</p>
                            <div>
                                <span>{t('translation:footer.tel')}: +852 2750 2117</span><span>{t('translation:footer.fax')}: +852 2750 2737</span>
                            </div>
                        </div>
                        <div>{t('translation:footer.hkWarehouse.name')}
                            <p>{t('translation:footer.address')}: {t('translation:footer.hkWarehouse.address')}</p>
                        </div>
                    </div>
                    <div>
                        <div>{t('translation:footer.szOffice.name')}
                            <p>{t('translation:footer.address')}: {t('translation:footer.szOffice.address')}<br/>{t('translation:footer.szOffice.contact')}
                            </p>
                        </div>
                        <div>{t('translation:footer.szWarehouse.name')}
                            <p>{t('translation:footer.address')}: {t('translation:footer.szWarehouse.address')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <p className={"copyright"}>Copyright © {year} Angie Express Limited</p>
        </footer>
    )
}