// import js
import React, {useContext} from "react";
import ReactModal from "react-modal";
import {BaseContext} from "./base";

export interface ModalBoxProps {
    label: string,
    children: React.ReactNode,
    className?: string,
    listOfOptions?: boolean,
}

export function ModalBox({label, children, className, listOfOptions}: ModalBoxProps) {
    return (
        <ReactModal isOpen={true}
                    contentLabel={label}
                    className={"modal-box-container"}
                    overlayClassName={"modal-box" + (className ? ' ' + className : '') + (listOfOptions ? ' list' : '')}
                    ariaHideApp={false}>
            {children}
        </ReactModal>
    )
}

interface BaseModalBoxContentProps {
    header: string,
    headerClassName?: string,
    children: React.ReactNode,
}

export function BaseModalBoxContent({header, headerClassName, children}: BaseModalBoxContentProps) {
    const baseContext = useContext(BaseContext);
    return (
        <>
            <div className={'content'}>
                <header className={"modal-box-header" + (headerClassName ? ' ' + headerClassName : '')}>
                    <h2>{header}</h2>
                    <button className={"modal-box-close"} onClick={() => {
                        baseContext.setModalBox(null);
                    }}/>
                </header>
                {children}
            </div>
        </>
    )
}

export default ModalBox;